import { Prompt } from "../../components/Prompt";
import {
  Box,
  Button,
  Card,
  CardActionArea, CardActions,
  CardContent, CardMedia, Checkbox, FormHelperText,
  Grid, Stack,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { InfoToolTip } from "../../components/InfoTooltip";
// import logo1 from "../../static/Cross Pixel NEXT-Transparent-01.png";
import logo1 from "../../static/NEXT_logo_cropped.png";
import ksrt from "../../static/K_S-RT_Logo_cropped.png";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "space-evenly",
    alignItems: "space-evenly",
    textAlign: "center",
    padding: theme.spacing(10)
  },
  CardBase: {},
  CardMain: {
    height: "90%"

    //textAlign: "center"
  },
  CardStack: {
    display: "flex",
    height: "100%",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  CheckedCookie: {
    backgroundColor: theme.palette.primary.dark
  },
  GridItem: {
    maxWidth: 400
    //textAlign: "center"
  }
}));

const Reach2 = (props) => {
  const classes = useStyles();
  const { slideId, activeField, setActive, lastPrompt, setValidationList } = props;
  const { register, setError, clearErrors, setValue, getValues, formState: { errors } } = useFormContext();

  const [checkedCookie, setCheckedCookie] = useState(false);
  const [checkedNext, setCheckedNext] = useState(false);
  const [checkedContextual, setCheckedContextual] = useState(false);

  const hasAudienceType = (value) => {
    const types = getValues(["cookieSelect", "nextSelect", "contextualSelect"]);
    //console.log(types);

    return !(!types[0] && !types[1]);
  };

  useEffect(() => {
    if (activeField === slideId) {
      //console.log("Active field2:" + activeField);
      setValidationList(["cookieSelect"]);
    }
  }, [activeField, slideId]);

  useEffect(() => {
    setValue("cookieSelect", checkedCookie);
    //console.log(getValues());
  },[checkedCookie])

  useEffect(() => {
    setValue("nextSelect", checkedNext);
    //console.log(getValues());
  },[checkedNext])

  useEffect(() => {
    setValue("contextualSelect", checkedContextual);
    //console.log(getValues());
  },[checkedContextual])

  //useeffect setvalue default false
  //todo: add validate where one is always required

  const handleCheckedCookie = async () => {
    const newValue = Boolean(!checkedCookie);
    setCheckedCookie(newValue);
    //setValue("cookieSelect", newValue);
    /*if (newValue === true) {
      clearErrors("cookieSelect");
    }*/
  };

  const handleCheckedNext = async () => {
    const newValue = Boolean(!checkedNext);
    setCheckedNext(newValue);
  };

  const handleCheckedContextual = async () => {
    const newValue = Boolean(!checkedContextual);
    setCheckedContextual(newValue);
    //setValue("contextualSelect", newValue);
    /*if (newValue === true) {
      clearErrors("cookieSelect"); //Error value is only on this field, so it might look weird but it's correct
    }*/
  };

//${checkedCookie && classes.CheckedCookie}
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Prompt prompt={"Select the audience type(s) you would like to create"} index={2} />
      </Grid>
      <Grid item lg={8} xs={8} className={classes.GridItem}>
        <Card elevation={1} sx={{
          height: "50vh",
          mb: 2,
          border: 1,
          borderColor: "grey.500",
          backgroundColor: checkedCookie ? "primary.dark" : ""
        }}
              className={`classes.CardBase `}>
          <CardActionArea id={1} onClick={handleCheckedCookie} sx={{ height: "100%", pt: 0, pb: 3 }}>
            <CardContent className={classes.CardMain}>
              <Stack className={classes.CardStack}>
                {/*<Typography variant="h3" component="div">
                  BEHAVIORAL
                  <InfoToolTip
                    info={<React.Fragment>
                      <Typography variant="body1" color="inherit">
                        Hero Data tracks and monitors the web surfing activity and behaviors of over 450 million
                        users in North America and Latin America. Our platform identifies users based on their
                        search and research activities for products and services using keywords that indicate
                        strong interest. With decades of domain experience, Hero Data combines this data with
                        a unique understanding of cultural, social, and political trends that influence the
                        multicultural market. This allows us to create the most accurate audiences in the
                        industry. Users are seamlessly added to relevant audience segments, enabling targeted
                        ads as they browse the web, use apps, and engage with CTV.
                      </Typography>
                    </React.Fragment>
                    } />
                </Typography>*/}
                <Box
                  component="img"
                  sx={{
                    height: 80
                  }}
                  src={ksrt}
                />
                <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                  Keyword/Search Retargeting
                </Typography>
                <Typography variant="body2" color="text.secondary" component="div" display="block">
                  We provide the most precise audiences
                  based on the search and keyword
                  activity on over 450 million users on the
                  web, apps and CTV
                </Typography>
              </Stack>
            </CardContent>
            <Button component={"span"} {...register("cookieSelect", { validate: hasAudienceType })
            } variant={"outlined"} onClick={handleCheckedCookie}>Select</Button>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item lg={8} xs={8} className={classes.GridItem}>
        <Card elevation={1} sx={{
          height: "50vh",
          mb: 2,
          border: 1,
          borderColor: "grey.500",
          backgroundColor: checkedNext ? "primary.dark" : ""
        }}
              className={`classes.CardBase `}>
          <CardActionArea id={1} onClick={handleCheckedNext} sx={{ height: "100%", pt: 0, pb: 3 }}>
            {/*<CardMedia
              sx={{ height: 50, marginTop: '42px' }}
              image={logo1}
              title="NEXT"
            />*/}
            <CardContent className={classes.CardMain}>
              <Stack className={classes.CardStack}>

                {/*<Typography variant="h3" component="div">

                  <InfoToolTip
                    info={<React.Fragment>
                      <Typography variant="body1" color="inherit">

                      </Typography>
                    </React.Fragment>
                    } />
                </Typography>*/}
                <Box
                  component="img"
                  sx={{
                    height: 80
                  }}
                  src={logo1}
                />
                <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                  Advanced Contextual Targeting
                </Typography>
                <Typography variant="body2" color="text.secondary" component="div" display="block">
                  NEXT combines context, machine learning, and our deep understanding of audience behavior
                  to build custom segments based on the most relevant media placements for your audience.
                </Typography>
              </Stack>
            </CardContent>
            <Button component={"span"} {...register("nextSelect", { validate: hasAudienceType })
            } variant={"outlined"} onClick={handleCheckedNext}>Select</Button>
          </CardActionArea>
        </Card>
      </Grid>
     {/* <Grid item lg={3} xs={3} className={classes.GridItem}>
        <Card elevation={1}
              sx={{
                height: "50vh",
                mb: 2,
                border: 1,
                borderColor: "grey.500",
                backgroundColor: checkedContextual ? "primary.dark" : ""
              }}
              className={`classes.CardBase`}>
          <CardActionArea id={1} onClick={handleCheckedContextual} sx={{ height: "100%", pt: 0, pb: 3 }}>
            <CardContent className={classes.CardMain}>
              <Stack className={classes.CardStack}>
                <Typography variant="h3" component="div">
                  CONTEXTUAL
                  <InfoToolTip
                    info={<React.Fragment>
                      <Typography variant="body1" color="inherit">
                        Hero Data creates page-level contextual audiences, enabling marketers to reach their
                        desired audience based on the web pages and apps they visit. Leveraging our
                        unparalleled understanding of the multicultural marketplace, we identify true context and
                        meaning that resonate with multicultural audiences. This ensures that marketers can
                        effectively connect with users based on genuine cultural relevance.
                      </Typography>
                    </React.Fragment>
                    } />
                </Typography>
                <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                  Planit
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ display: "block" }}>
                  Planit is an advanced contextual targeting solution
                  that is powered by audience behavior & intent.
                  Planit goes beyond keywords on a page by including 
                  additional relevant pages to provide a comprehensive solution.
                </Typography>
              </Stack>
            </CardContent>
            <Button component="span"  {...register("contextualSelect")
            } variant={"outlined"} onClick={handleCheckedContextual}>Select</Button>
          </CardActionArea>
        </Card>
      </Grid>*/}
      <Grid item xs={12}>
        {errors.cookieSelect &&
        <FormHelperText id="component-error-text" sx={{textAlign:"center"}}
                         error>{"Please select at least one option"}</FormHelperText>}
      </Grid>
    </Grid>
  );
};

export { Reach2 };